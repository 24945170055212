function changeBgImage() {
    const rndInt = Math.floor(Math.random() * 65) + 1
    document.querySelector("html").style.backgroundImage = 'url(/img/cool_'+rndInt+'.gif)';
}

window.onload = function() {
    changeBgImage();
    window.onclick = () => {
        changeBgImage();
    }
};